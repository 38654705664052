<template>
  <b-card class="mb-3">
    <router-link :to="{ name: 'CommoditiesInitiative', params: { id: item.id }}"><h4 class="card-title">{{item.name}}</h4></router-link>
    <div>{{item.country}} | Implementation status: {{item.impactstatus.name}}</div>
    <div>{{item.headline}}</div>
    <div v-if="item.impactexperts.length > 0">
      <strong>Specialists: </strong>
      <span v-for="(expert, index) in item.impactexperts" :key="'expert-'+expert.id">
        <router-link :to="{ name: 'ImpactExpert', params: { id: expert.id }}">{{expert.forename}} {{expert.surname}}</router-link><a v-if="index + 1 < item.impactexperts.length"> | </a>
      </span>
    </div>
  </b-card>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'InitiativeCommodities',
  props: ['item', 'trim'],
  components: {
  },
  computed: {
  },
  created: function () {
    this.item.headline = _.truncate(this.item.headline, { length: this.trim, 'separator': ' ' })
  },
  data () {
    const data = {
      filter: ''
    }
    return data
  },
  methods: {
  }
}
</script>
